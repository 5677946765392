export const NOTIFY = 'ui/notify';
export const ADD_NOTIFICATION = 'ui/addNotification';
export const REMOVE_NOTIFICATION = 'ui/removeNotification';
export const STORE_REQUEST_TIME = 'ui/storeRequestTime';
export const SET_STATUSES = 'ui/setStatuses';
export const SET_BACKGROUND_LOADING = 'ui/setBackgroundLoading';
export const RELOAD_APP = 'ui/reloadApp';
export const READY = 'ui/ready';
export const SET_SHOW_SPLASHSCREEN = 'ui/setShowSplashscreen';
export const SET_SHOW_WELCOME_SCREEN = 'ui/setShowWelcomeScreen';
export const SET_SHOW_LOGOUT_SCREEN = 'ui/setShowLogoutScreen';
export const SET_SHOW_GDPR_SCREEN = 'ui/setShowGDPRScreen';
export const START_COSMOS = 'ui/startCosmos';
export const SET_SHOW_ADVANCED_SEARCH = 'ui/setShowAdvancedSearch';

