/* eslint-disable func-names */
import { takeLatest, put, spawn, fork, call } from 'redux-saga/effects';
import { setFolderId } from '../../Actions/repository';
import { OPEN_PROJECT } from '../../Actions/types/project';
import { saveUserSettings } from '../../Actions/userSettings';
import messageSaga from './messageSaga';
import widgetSaga from './widgetSaga';
import reportingSaga from './reportingSaga';
import settingsSaga from './settingsSaga';
import businessProcessSaga from './businessProcessSaga';
import repositoryApi from '../../Api/repository';
import { fetchAndStoreSettings } from '../userSettingsSaga';
import { userSettingsScope } from '../../Constants/userSettingsScope';

export default function* () {
  yield takeLatest(
    OPEN_PROJECT,
    function* ({ projectCode, resourceId, callback: openCallback }) {
      yield call(repositoryApi.clearCache);

      yield put(
        saveUserSettings(
          userSettingsScope.PROJECT_SELECTION,
          { projectCode },
          true
        )
      );

      yield fork(settingsSaga, projectCode);
      yield fork(messageSaga, projectCode);
      yield fork(widgetSaga, projectCode);
      yield fork(businessProcessSaga, projectCode);

      yield fork(reportingSaga, projectCode, resourceId);

      yield put(setFolderId(projectCode, resourceId));
      yield spawn(openCallback);
    }
  );

  yield call(fetchAndStoreSettings, null, userSettingsScope.PROJECT_SELECTION);
}
