import { call, getContext } from 'redux-saga/effects';
import * as _ from 'lodash-es';

import repositoryApi from '../../Api/repository';
import { toQueryProperties } from '../../Utils/projectUtils';
import { parseGatewayResources } from '../../Utils/documentUtils';
import { updateDocuments } from './documentSelectionSaga';


/**
 * Generates a dependency map for the given properties
 * @param properties
 * @returns {{[p: string]: any}|{}}
 */
export function generatePropertiesDependencyMap(properties) {
  if (!properties) return {};

  return _.chain(properties)
    .filter((prop) => prop.isDependant())
    .keyBy('name')
    .mapValues('dependency')
    .value();
}

/**
 * Get all properties that depend on the given parentPropertyName
 * @param dependencyMap
 * @param parentPropertyName
 * @returns {string[]}
 */
export function getDependantProperties(dependencyMap, parentPropertyName) {
  const children = Object.keys(dependencyMap).filter(
    (key) => dependencyMap[key] === parentPropertyName
  );

  children.forEach((child) => {
    children.push(...getDependantProperties(dependencyMap, child));
  });

  return children;
}

export function* addURLtoResources(resources) {
  const projectService = yield getContext('projectService');
  const project = yield call(projectService.getOpenedProject);

  const getResourceUrl = (resource) => {
    let parts = [];

    const projectCode = project?.code;
    if (projectCode != null) {
      parts = [...parts, 'module', projectCode];
    }

    switch (resource.resourcetype) {
      case 1:
        parts = [...parts, 'folder', resource.identifier];
        break;
      case 2:
        parts = [...parts, 'document', resource.identifier];
        break;
      default:
        break;
    }

    return window.location.origin + '/' + parts.join('/');
  };

  return {
    resourcesWithUrls: resources.map((r) => ({
      ...r,
      url: getResourceUrl(r),
    })),
    project,
  };
}

// Updates documents filename adding _cos_{Doc_Nr} as suffix
export function* updateDocumentsFilename(folderId, documentIds) {
  const documents = yield call(fetchDocumentsByIds, folderId, documentIds);
  const updatedDocuments = documents.map((document) => {
    return {
      ...document,
      filename: getFilename(document.filename, document.DocumentNumber),
    };
  });
  yield call(updateDocuments, updatedDocuments);
  return documents;
}

export function* fetchDocumentsByIds(folderId, documentIds) {
  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getProperties);
  const requestedProps = toQueryProperties(properties);

  try {
    const items = yield call(
      repositoryApi.getDocumentsByIds,
      folderId,
      documentIds,
      requestedProps
    );
    const documents = parseGatewayResources(items, properties);
    return documents;
  } catch (err) {
    console.error(err);
  }

  return [];
}

export function getFilename(docFilename, documentNr) {
  const filenameParts = docFilename.split('.');
  const fileExtension = filenameParts.pop();
  const baseFilename = filenameParts.join('.');
  const docNr = Array.isArray(documentNr) ? documentNr[0] : documentNr;
  return baseFilename.includes(docNr)
    ? `${baseFilename}.${fileExtension}`
    : `${baseFilename}_cos_${docNr}.${fileExtension}`;
}
