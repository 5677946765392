import * as _ from 'lodash-es';
import { createSelector } from 'reselect';
import { userSettingsScope } from '../Constants/userSettingsScope';

export const getUserSettings = (state) => state.ui.userSettings;
export const getFixedAttributes = createSelector(
  (state) => state.ui.userSettings[userSettingsScope.FIXED_ATTRIBUTES],
  (fixedAttributes) => fixedAttributes?.data
);
export const getUploadPresets = createSelector(
  (state) => state.ui.userSettings[userSettingsScope.UPLOAD_PRESETS],
  (uploadPresets) => uploadPresets?.data
);
export const getDefaultFilters = createSelector(
  (state) => state.ui.userSettings[userSettingsScope.DEFAULT_FILTERS],
  (defaultFilters) => defaultFilters?.data
);
export const getProjectSelection = createSelector(
  (state) => state.ui.userSettings[userSettingsScope.PROJECT_SELECTION],
  (projectSelection) => projectSelection?.data
);
export const getAppearance = createSelector(
  (state) => state.ui.userSettings[userSettingsScope.APPEARANCE],
  (appearance) => appearance?.data
);

export const getFixedAttributesMap = createSelector(
  getFixedAttributes,
  (fixedAttributes) =>
    _.chain(fixedAttributes).keyBy('property').mapValues('data').value()
);
