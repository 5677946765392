import * as actions from './types';

/**
 * UI action generators.
 * @module actions/ui
 * @category Actions
 */

/**
 * Action to set ready.
 * @function
 * @param {boolean} ready - status of readiness
 */
export const setReady = (ready = true) => ({
  type: actions.ui.READY,
  ready,
});

/**
 * Action to show splash screen.
 * @function
 * @param {} show - status flag
 */
export const showSplashScreen = (show) => ({
  type: actions.ui.SET_SHOW_SPLASHSCREEN,
  showSplashScreen: show,
});

/**
 * Action to show welcome screen.
 * @function
 * @param {} show - status flag
 */
export const showWelcomeScreen = (show) => ({
  type: actions.ui.SET_SHOW_WELCOME_SCREEN,
  showWelcomeScreen: show,
});

/**
 * Action to show GDPR screen.
 * @function
 * @param {boolean} show - status flag
 */
export const showGDPRScreen = (show = true) => ({
  type: actions.ui.SET_SHOW_GDPR_SCREEN,
  showGDPRScreen: show,
});

/**
 * Action to notify.
 * @function
 * @param {string} message - message text
 * @param {string} notificationType - type of the notification
 */
export const notify = (message, notificationType = 'info') => ({
  type: actions.ui.NOTIFY,
  message,
  notificationType,
});

/**
 * Action to add notification.
 * @function
 * @param {} notification -
 */
export const addNotification = (notification) => ({
  type: actions.ui.ADD_NOTIFICATION,
  notification,
});

/**
 * Action to remove notification.
 * @function
 * @param {} id -
 */
export const removeNotification = (id) => ({
  type: actions.ui.REMOVE_NOTIFICATION,
  id,
});

/**
 * Action to store request time.
 * @function
 * @param {} millis -
 */
export const storeRequestTime = (millis) => ({
  type: actions.ui.STORE_REQUEST_TIME,
  millis,
});

/**
 * Action to set statuses.
 * @function
 * @param {} statuses -
 */
export const setStatuses = (statuses) => ({
  type: actions.ui.SET_STATUSES,
  statuses,
});

/**
 * Action to set background loading.
 * @function
 * @param {boolean} loading - status flag of background loading
 * @param {string} message - message text
 */
export const setBackgroundLoading = (loading, message) => ({
  type: actions.ui.SET_BACKGROUND_LOADING,
  loading,
  message,
});

/**
 * Action to reload app.
 * @function
 */
export const reloadApp = () => ({
  type: actions.ui.RELOAD_APP,
});

/**
 * Action to start cosmos.
 * @function
 */
export const startCosmos = () => ({
  type: actions.ui.START_COSMOS,
});

/**
 * Action to show advanced search.
 * @function
 * @param {boolean} advancedSearch - status flag
 */
export const setShowAdvancedSearch = (advancedSearch) => ({
  type: actions.ui.SET_SHOW_ADVANCED_SEARCH,
  advancedSearch,
});

