import ForbiddenError from './ForbiddenError';
import UnauthorizedError from './UnauthorizedError';

export default (error) => {
  const { response } = error;
  if (response != null) {
    const { headers, data } = response;
    const message = headers['al-error-message'] || data?.message;
    // const code = headers['al-error-code'];

    if (response.status === 401) {
      return Promise.reject(new UnauthorizedError(message));
    }

    if (response.status === 403) {
      // return Promise.reject(ForbiddenError.accessDenied());
      return Promise.reject(ForbiddenError.strictlyConfidential(message));
    }

    if (data instanceof Blob && data.type === 'application/json') {
      return new Promise((r, reject) => {
        const reader = new FileReader();

        reader.onloadend = () => {
          if (reader.result != null) {
            const jsonData = JSON.parse(reader.result);

            if (
              jsonData.message != null &&
              jsonData.message.includes(
                'Resource is secured by access management'
              )
            ) {
              // return reject(new ForbiddenError(jsonData.message));
              return reject(
                ForbiddenError.strictlyConfidential(jsonData.message)
              );
            }

            return reject(new Error(message));
          }

          return reject(new Error('Cannot parse response body as a JSON.'));
        };

        reader.readAsText(data);
      });
    }

    if (message != null) {
      if (message.includes('Resource is secured by access management')) {
        return Promise.reject(ForbiddenError.strictlyConfidential(message));
      }

      return Promise.reject(new Error(message));
    }

    return Promise.reject(response.data);
  }

  return Promise.reject(error);
};

