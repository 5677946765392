import {
  put,
  call,
  takeLatest,
  select,
  take,
  fork,
  cancel,
  delay,
  getContext,
} from 'redux-saga/effects';
import * as _ from 'lodash-es';
import {
  setDocuments,
  setPaginatorPageCount,
  setRepositoryLoading,
  setPaginatorPage,
  updateFilterSubtree,
} from '../../Actions/repository';
import * as actions from '../../Actions/types';
import { getComputedFilter, getOrderBy } from '../../Selectors/repository';
import { parseGatewayResources } from '../../Utils/documentUtils';
import callApi from '../Effects/callApi';
import { notify } from '../../Actions/ui';
import { exportDocumentsExcel, exportTitleUrlExcel } from './exportExcelSaga';
import { toQueryProperties } from '../../Utils/projectUtils';
import complete from '../Effects/complete';
import { getAppearance } from '../../Selectors/ui';
import { EXPORT_URLS_EXCEL } from '../../Actions/types/repository';
import { getSearchOperation } from 'cosmos-core';
import repository from '../../Api/repository';
import {
  LOCAL_STORAGE_TEMPORARY_BUCKET,
  FOLDER_TYPE_TEMPORARY_BUCKET,
} from '../../Utils/constants';

const getFolderTypeFilter = (filter) => {
  const { foldertype } = filter;
  if (
    foldertype == null ||
    (Array.isArray(foldertype) && foldertype.length === 0)
  ) {
    return _.omit(
      {
        ...filter,
        resourcetype: 2,
      },
      'foldertype'
    );
  }

  return {
    ...filter,
    resourcetype: 1,
  };
};

function* getSearchOptions(properties) {
  const filter = yield select(getComputedFilter);
  const project = yield select((state) => state.project);
  const projectName = project?.openedProjectCode;
  const filterConfig = yield select((state) => state.repository.filterConfig);

  const filterCopy = _.cloneDeep(filter);

  if (filterCopy.foldertype === FOLDER_TYPE_TEMPORARY_BUCKET) {
    const storedBuckets = localStorage.getItem(LOCAL_STORAGE_TEMPORARY_BUCKET);
    const parsedBuckets = storedBuckets ? JSON.parse(storedBuckets) : {};

    filterCopy.foldertype = null;
    filterCopy.identifier = parsedBuckets[projectName] || [];
  }

  const advancedFilter = _.mapValues(
    getFolderTypeFilter(filterCopy),
    (v, propertyName) => {
      if (_.has(v, 'value') && _.has(v, 'operator')) {
        return v;
      }

      if (Array.isArray(v) && v.length > 1) {
        return {
          value: v,
          operator: filterConfig[propertyName]?.multiMode,
        };
      }

      return v;
    }
  );

  const substructure = yield select((state) => state.repository.substructure);
  const searchQuery = yield select((state) => state.repository.searchQuery);
  const searchProperties = yield select(
    (state) => state.repository.searchProperties
  );
  const { isFulltext, signatureStatus } = yield select(
    (state) => state.repository.searchOptions
  );

  const searchOperation = getSearchOperation(advancedFilter, properties, {
    substructure,
    searchQuery,
    fulltextSearch: isFulltext,
    searchProperties: searchProperties.map((p) => p.name),
    signatureStatus,
  });

  const forbiddenQueryPatterns = [/\*\*/g];

  let sanitizedSearchQuery = '';
  if (searchQuery?.trim().length) {
    forbiddenQueryPatterns.forEach((pattern) => {
      sanitizedSearchQuery = searchQuery.replace(pattern, '');
    });
  }

  return {
    searchQuery:
      isFulltext && searchQuery?.trim().length
        ? `${sanitizedSearchQuery}`
        : null,
    operation: searchOperation,
  };
}

function* fetchDocuments(projectCode, folderId, paginator, force = false) {
  yield put(setRepositoryLoading(true, 'Loading documents'));

  const orderBy = yield select(getOrderBy);

  const projectService = yield getContext('projectService');
  const properties = yield call(projectService.getProperties);
  const requestedProps = toQueryProperties(properties);

  // const filterableProps = properties
  //   .filter((p) => p.filterable)
  //   .map((p) => p.name);

  if (requestedProps.length <= 0) {
    throw new Error('Empty requested properties array.');
  }

  const filter = yield select(getComputedFilter);

  // Uncommenting this as this is causing a bug while at the same time not serving any purpose for the app
  // Origin: Personal Filter handling in the past (2022)
  // Also: This is a side effect of the function
  // Leaving it here for documentation
  // const folderProperties = yield call(projectService.getFolderProperties);
  //
  // const folderPropertiesNames = _(folderProperties)
  //   .filter((p) => p.editable || p.system)
  //   .map('name')
  //   .value();
  //
  // const filteringDocuments = _(filter).some((v, key, f) => {
  //   return !folderPropertiesNames.includes(key) && f.resourcetype !== 1;
  // });
  // if (filteringDocuments && !subtree) {
  //   yield put(updateFilterSubtree());
  // }

  const subtree = yield select((state) => state.repository.subtree);
  if (filter.resourcetype === 1 && subtree) {
    yield put(updateFilterSubtree(false));
  }

  try {
    const searchOptions = yield call(getSearchOptions, properties);

    const { items, count } = yield callApi(
      repository.searchRepository,
      projectCode,
      folderId,
      requestedProps,
      {
        ...searchOptions,
        paginator,
        orderBy,
        subtree,
      },
      force
    );

    const documents = parseGatewayResources(items, properties);

    // const filterOptions = buildFilters(documents, filterableProps);
    // yield put(setFilterOptions(filterOptions));

    return { documents, count };
  } catch (err) {
    yield put(
      notify(
        `Loading documents failed with following reason: ${err.message}`,
        'error'
      )
    );
    console.error(err);
    return { documents: [], count: 0 };
  } finally {
    yield put(setRepositoryLoading(false));
  }
}

function* exportFolderExcel(projectCode, folderId, action) {
  const { documents } = yield call(fetchDocuments, projectCode, folderId);

  const { options } = action.payload;

  yield call(exportDocumentsExcel, documents, options.columns || []);
  yield complete(action);
}

function* repositorySearchSaga(projectCode, resourceId) {
  yield takeLatest(actions.repository.EXPORT_FOLDER_EXCEL, function* (action) {
    yield call(exportFolderExcel, projectCode, resourceId, action);
  });

  // eslint-disable-next-line func-names
  yield takeLatest(EXPORT_URLS_EXCEL, function* ({ allDocuments }) {
    if (allDocuments) {
      const { documents } = yield call(fetchDocuments, projectCode, resourceId);
      yield call(exportTitleUrlExcel, documents);
    }
  });

  let lastTask;

  while (true) {
    const { type, payload } = yield take([
      actions.repository.REQUEST_DOCUMENTS,
      actions.repository.UPDATE_FILTER,
      actions.repository.SET_FILTER,
      actions.repository.CLEAR_FILTER,
      actions.repository.CLEAR_SEARCH_QUERY,
      actions.repository.UPDATE_SEARCH_QUERY,
      actions.repository.SET_ORDERBY,
      actions.filterFolder.SET_FILTER_FOLDER,
      actions.repository.SET_PAGINATOR_PAGE,
      actions.repository.SET_REPOSITORY_OPTIONS,
      actions.repository.REFRESH_REPOSITORY_SEARCH,
      actions.repository.SET_SEARCH_OPTIONS,
    ]);

    if (lastTask) {
      yield cancel(lastTask);
    }

    // eslint-disable-next-line func-names
    lastTask = yield fork(function* () {
      if (
        ![
          actions.repository.SET_PAGINATOR_PAGE,
          actions.repository.REFRESH_REPOSITORY_SEARCH,
        ].includes(type)
      ) {
        yield put(setPaginatorPage(0));
      }

      yield delay(250);
      yield put(setDocuments([]));

      const { currentPage: number, pageSize } = yield select(
        (state) => state.repository.paginator
      );

      const appearanceSettings = yield select(getAppearance);
      const size = appearanceSettings?.pageSize || pageSize;

      const options = yield select((state) => state.repository.options);

      const paginator =
        payload?.options?.disablePagination || options.disablePagination
          ? null
          : { number, size };

      const { documents, count } = yield call(
        fetchDocuments,
        projectCode,
        resourceId,
        paginator,
        type === actions.repository.REFRESH_REPOSITORY_SEARCH &&
          !!payload?.force
      );

      yield put(setDocuments(documents, count));
      yield put(setPaginatorPageCount(Math.ceil(count / size)));
    });
  }
}

export default repositorySearchSaga;

