import { property, valuesetSource } from 'cosmos-config/generator';

export default [
  property('Reminder', 'reminder')
    .selectableAttribute('reminder', 'reminder', valuesetSource.STATIC_VALUESET)
    .valueset([
      {
        label: 'Daily',
        value: 'daily',
      },
      {
        label: 'Every Third Day',
        value: 'thirdDay',
      },
      {
        label: 'Weekly',
        value: 'weekly',
      },
    ])
    .optional()
    .describe(
      'Please select the interval at which the signer shall be reminded.'
    ),
  property('Message', 'message')
    .textArea(500)
    .optional()
    .describe(
      'Please enter additional information to be displayed in the signatory notification.'
    ),
];
